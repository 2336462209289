import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FormBaseModal } from '@shared/helpers/formBaseModal';
import { RateService } from '@shared/services/rate.service';
import {Router} from "@angular/router";

@UntilDestroy()
@Component({
  selector: 'app-modal-exchange-rate-create',
  templateUrl: './modal-exchange-rate-create.component.html',
  styleUrls: ['./modal-exchange-rate-create.component.scss']
})
export class ModalExchangeRateCreateComponent extends FormBaseModal<any> {

  date: Date;
  previousRates: string;

  constructor(public bsModalRef: BsModalRef,
              public rateService: RateService,
              private _router: Router,
              private fb: FormBuilder
  ) {
    super();
  }

  onSubmit() {
    if (this.formData.valid) {
      this.loading = true;
      const rates = [];
      this.rateService.ccyPairsList.forEach(pair => {
        rates.push({
          original_currency: pair.original_currency,
          target_currency: pair.target_currency,
          rate: this.formData.value[`sell_${pair.original_currency}_${pair.target_currency}`],
        });
        rates.push({
          original_currency: pair.target_currency,
          target_currency: pair.original_currency,
          rate: this.formData.value[`buy_${pair.original_currency}_${pair.target_currency}`]
        });
      });
      this.rateService.createRates(rates)
        .pipe(
          untilDestroyed(this),
          finalize(() => this.loading = false)
        )
        .subscribe((response: any) => {
          this.onClose.next(response);
          this._router.navigate(['accounting/exchange-rates']);
          this.bsModalRef.hide();
        });
    } else {
      this.formData.markAllAsTouched();
    }
  }

  createForm() {
    this.formData = this.fb.group({});
    this.rateService.ccyPairsList.forEach(pair => {
      this.formData.addControl(`sell_${pair.original_currency}_${pair.target_currency}`, this.fb.control('', [Validators.required]));
      this.formData.addControl(`buy_${pair.original_currency}_${pair.target_currency}`, this.fb.control('', [Validators.required]));
    });
  }
}
