import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import { API_BASE } from '@shared/helpers/constants';
import {MainRatesListPayload, MainRatesListResponse, RatePair} from "@shared/models/ccyRates";

@Injectable({
  providedIn: 'root'
})
export class RateService {
  ccyPairsList = [
    {original_currency: 'GBP', target_currency: 'USD'},
    {original_currency: 'GBP', target_currency: 'EUR'},
    {original_currency: 'GBP', target_currency: 'AED'},
    {original_currency: 'EUR', target_currency: 'USD'},
    {original_currency: 'EUR', target_currency: 'AED'},
    {original_currency: 'USD', target_currency: 'AED'}
  ];

  _ratesData: MainRatesListResponse;
  rateUpdNeeded: boolean;

  get ratesData() {
    return this._ratesData;
  }

  set ratesData(data) {
    if (!data.pairsObj) {
      data.pairsObj = this.convertToPairsObj(data.rates);
    }
    this._ratesData = data;
    const givenDate = new Date(data.created_at);
    const currentDate = new Date();
    const date15DaysAgo = new Date();
    date15DaysAgo.setDate(currentDate.getDate() - 15);
    this.rateUpdNeeded = givenDate < date15DaysAgo;
  }

  constructor(private _http: HttpClient) {
  }

  convertToPairsObj(ccy: RatePair[]) {
    const obj = {};
    this.ccyPairsList.forEach(pair => {
      const sell = ccy.find(item =>
        pair.original_currency === item.original_currency.named && pair.target_currency === item.target_currency.named
      );
      if (sell) {
        obj['sell_' + pair.original_currency + '_' + pair.target_currency] = sell.rate;
      }
      const buy = ccy.find(item =>
        pair.target_currency === item.original_currency.named && pair.target_currency === item.original_currency.named
      );
      if (buy) {
        obj['buy_' + pair.original_currency + '_' + pair.target_currency] = buy.rate;
      }
    });
    return obj;
  }

  getRates(queryParams: MainRatesListPayload = null): Observable<MainRatesListResponse[]> {
    return this._http.get(`${API_BASE}/rates/`, {params: {...queryParams}}).pipe(
      catchError(error => {
        return of({error: 'Error fetching data'});
      }),
      map(response => response['data'])
    );
  }

  createRates(rates: RatePair[]): Observable<MainRatesListResponse> {
    return this._http.post(`${API_BASE}/rates/`, rates).pipe(
      map(response => response['data'])
    );
  }

  getCurrentRates(): Observable<MainRatesListResponse> {
    return this._http.get(`${API_BASE}/exchange-rates/get-latest-rate/`).pipe(
      map(response => response['data'])
    );
  }

  list(year: number, month: number, params?: any): Observable<any[]> {
    return this._http.get(`${API_BASE}/exchange-rates/${year}/${month}/`, {params})
      .pipe(
        map(response => response['data'])
      );
  }

  rate(params?: any): Observable<any> {
    return this._http.get(`${API_BASE}/exchange-rates/get-last-rate/`, { params })
      .pipe(
        map(response => response['data'])
      );
  }

  create(data: any): Observable<any> {
    return this._http.post<any>(`${API_BASE}/exchange-rates/`, data)
      .pipe(
        map(response => response['data'])
      );
  }

  update(data: any): Observable<any> {
    return this._http.patch<any>(`${API_BASE}/exchange-rates/${data.id}/`, data)
      .pipe(
        map(response => response['data'])
      );
  }

  delete(id: string): Observable<any> {
    return this._http.delete(`${API_BASE}/exchange-rates/${id}/`);
  }

}
