import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {WrapperComponent} from '@shared/layouts/wrapper/wrapper.component';
import {TokenService} from '@shared/services/token.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module')
      .then(m => m.LoginModule)
  },
  {
    path: '',
    component: WrapperComponent,
    canActivate: [TokenService],

    children: [
      {
        path: 'requests',
        loadChildren: () => import('./pages/requests/requests.module')
          .then(m => m.RequestsModule)
      },
      {
        path: 'tasks',
        loadChildren: () => import('./pages/tasks/tasks.module')
          .then(m => m.TasksModule)
      },
      {
        path: 'mail',
        loadChildren: () => import('./pages/mail/mail.module')
          .then(m => m.MailModule)
      },
      {
        path: 'directory',
        loadChildren: () => import('./pages/directory/directory.module')
          .then(m => m.DirectoryModule)
      },
      {
        path: 'directory/clients',
        loadChildren: () => import('./pages/directory/clients/clients.module')
          .then(m => m.ClientsModule)
      },
      {
        path: 'directory/suppliers',
        loadChildren: () => import('./pages/directory/suppliers/suppliers.module')
          .then(m => m.SuppliersModule)
      },
      {
        path: 'directory/managers',
        loadChildren: () => import('./pages/directory/managers/managers.module')
          .then(m => m.ManagersModule)
      },
      {
        path: 'directory/services',
        loadChildren: () => import('./pages/directory/services/services.module')
          .then(m => m.ServicesModule)
      }, {
        path: 'directory/banks',
        loadChildren: () => import('./pages/directory/banks/banks.module')
          .then(m => m.BanksModule)
      },
      {
        path: 'directory/companies',
        loadChildren: () => import('./pages/directory/companies/companies.module')
          .then(m => m.CompaniesModule)
      },
      /*  {
         path: 'directory/offices',
         loadChildren: () => import('./pages/directory/offices/offices.module')
           .then(m => m.OfficesModule)
       },*/
      {
        path: 'documents',
        loadChildren: () => import('./pages/documents/documents.module')
          .then(m => m.DocumentsModule)
      },
      /*  {
        path: 'pipeline',
        loadChildren: () => import('./pages/pipeline/pipeline.module')
          .then(m => m.PipelineModule)
      },*/
      {
        path: 'accounting-workspace',
        loadChildren: () => import('./pages/accounting-workspace/accounting-workspace.module')
          .then(m => m.AccountingWorkspaceModule)
      }, {
        path: 'accounting',
        loadChildren: () => import('./pages/accounting/accounting.module')
          .then(m => m.AccountingModule)
      },
      {
        path: 'accounting/client-invoices',
        loadChildren: () => import('./pages/accounting/client-invoices/client-invoices.module')
          .then(m => m.ClientInvoicesModule)
      },
      {
        path: 'accounting/supplier-invoices',
        loadChildren: () => import('./pages/accounting/supplier-invoices/supplier-invoices.module')
          .then(m => m.SupplierInvoicesModule)
      },
      {
        path: 'accounting/matched-data',
        loadChildren: () => import('./pages/accounting/matched-data/matched-data.module')
          .then(m => m.MatchedDataModule)
      },
      {
        path: 'accounting/accounting-data',
        loadChildren: () => import('./pages/accounting/accounting-data/accounting-data.module')
          .then(m => m.AccountingDataModule)
      },
      {
        path: 'accounting/invoices-and-bills',
        loadChildren: () => import('./pages/accounting/transactions/transactions.module')
          .then(m => m.TransactionsModule)
      },
      {
        path: 'accounting/exchange-rates',
        loadChildren: () => import('./pages/accounting/exchange-rates/exchange-rates.module')
          .then(m => m.ExchangeRatesModule)
      },
      {
        path: 'accounting/bank-cards',
        loadChildren: () => import('./pages/accounting/bank-cards/card.module')
          .then(m => m.CardModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module')
          .then(m => m.UsersModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module')
          .then(m => m.ProfileModule)
      },
      {
        path: 'search',
        loadChildren: () => import('./pages/search/search.module')
          .then(m => m.SearchModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./pages/notifications/notifications.module')
          .then(m => m.NotificationsModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module')
          .then(m => m.ReportsModule)
      },
      {
        path: 'recover',
        loadChildren: () => import('./pages/recover/recover.module')
          .then(m => m.RecoverModule)
      },
      {
        path: '',
        redirectTo: 'requests',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
